(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

require("./vendors/h5bp");

/* 
 * Mapa mediante la API de Google Maps
 */

function googleMaps() {

  // Ubicación LAT
  var lugar = new google.maps.LatLng(-31.740424, -60.522018);
  var centro = new google.maps.LatLng(-31.740424, -60.522018);

  // Estilos
  var estilos = [{ "featureType": "landscape", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#000000" }] }, { "featureType": "water", "stylers": [{ "color": "#000000" }] }];

  // Opciones del mapa
  var mapOptions = {
    center: centro,
    zoom: 16,
    styles: estilos,
    scrollwheel: false
  };

  // Crear mapa
  var map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);

  // Marcador
  var marker = new google.maps.Marker({
    position: lugar,
    icon: "../assets/media/location-pin.png",
    map: map, //lo asocio al mapa
    optimized: false,
    title: 'AgroPlan'
  });

  var infoWindow = new google.maps.InfoWindow({
    content: "<p style='text-align:center'>Gualeguaychú 745 <br> Paraná | Entre Ríos | Argentina</p>"
  });

  google.maps.event.addListener(marker, 'click', function () {
    infoWindow.open(map, marker);
  });
}

/* 
 * Ejecuto funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
function goMaterialize() {
  $(".button-collapse").sideNav();
  $('.parallax').parallax();
  $('.tabs').tabs();
  $('select').material_select();
  $('.datepicker').pickadate({
    selectMonths: true, // Creates a dropdown to control month
    selectYears: 100, // Creates a dropdown of 15 years to control year,
    // today: 'Today',
    // clear: 'Clear',
    close: 'Ok',
    closeOnSelect: false // Close upon selecting a date,
  });

  $(".switch").find("input[type=checkbox]").on("change", function () {
    if ($(this).prop('checked') === true) $(".job-information").fadeIn();else $(".job-information").fadeOut();
  });
  $('#contactMsg').modal();
  $('#contactMsg').modal('open');
}

/* 
 * Ejecuto funciones de visualización mediante fancybox
 *
 * - Ejecucion en: $(document).ready()
 */
function goFancy() {
  $(".fancybox").fancybox({
    padding: 0
  });

  $(".htmlpopup").fancybox({
    type: 'ajax'
  });
}

/* 
 * Ejecuto funciones para obtener la misma altura en todos los boxes de noticias
 *
 * - Ejecucion en: $(document).ready()
 */
function sameHeight() {
  var maxHeight = 430;
  $(".news-box").each(function () {
    if ($(this).height() > maxHeight) {
      maxHeight = $(this).height();
    }
  }).promise().done(function () {
    $(".news-box").css("height", maxHeight);
  });
}

/* 
 * Ejectuo las llamadas a slick
 *
 * - Ejecucion en: $(document).ready()
 */
function goSlick() {

  $('.plans-slider').slick({
    arrows: false,
    dots: true,
    autoplay: true,
    speed: 400,
    pauseOnHover: false,
    cssEase: 'linear'
  });

  $('.news-image-slider').slick({
    arrows: false,
    dots: true,
    autoplay: false,
    speed: 300,
    pauseOnHover: false,
    cssEase: 'linear'
  });

  $('.news-slider').slick({
    autoplay: true,
    dots: false,
    arrows: true,
    speed: 100,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });

  $('.gallery-slider').slick({
    autoplay: true,
    dots: false,
    arrows: false,
    speed: 200,
    infinite: true,
    slidesToShow: 5,
    pauseOnHover: false,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }]
  });

  $('.adjudicated-gallery-slider').slick({
    autoplay: true,
    dots: false,
    arrows: true,
    speed: 200,
    infinite: true,
    slidesToShow: 5,
    pauseOnHover: false,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }]
  });
}

/* 
 * Camio algunos estilos al momento de scrollear
 *
 * - Ejecucion en: $(document).ready()
 */
function headerStuff() {
  //logo resize
  $(window).on('scroll', function () {
    var scrollTop = $(window).scrollTop();
    if (scrollTop > 200 && $(window).width() < 600) {
      //si ya está mas abjo de los 200px y la pantalla es menor a 600px
      $('header nav').addClass("escort");
    } else {
      $('header nav').removeClass("escort");
    }
  });
}

/*
 * 
 * Ejecuto el llamado de todas las funciones Nac y Pop
 *
 */
$(document).ready(function () {

  goMaterialize();
  goFancy();
  goSlick();
  headerStuff();

  if ($("#map-canvas").length) googleMaps();
  if ($(".news-box").length) sameHeight();

  $("#request-number").maskAsNumber();
});

},{"./vendors/h5bp":2}],2:[function(require,module,exports){
'use strict';

// Avoid `console` errors in browsers that lack a console.
(function () {
  'use strict';

  var method;
  var noop = function noop() {};
  var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'];
  var length = methods.length;
  var console = window.console = window.console || {};

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
})();

},{}]},{},[1])

